<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <Header
                @onAdd="() => (isShow = true)"
                :title="'Survey Data'"
                :count="items && items.length"
                is-not-add
                :is-show-search-box="false"
            />
            <div class="flex justify-end items-center px-4">
                <!--				<Text size="sm" color="primary-600" content="View Interview Schedule" decoration="underline" custom-class="cursor-pointer"/>-->
                <div class="flex items-center space-x-3">
                    <!--					<Text size="sm" color="primary-600" content="Import Contacts" decoration="underline" custom-class="cursor-pointer"/>-->
                    <Text
                        size="sm"
                        color="primary-600"
                        content="Export as CSV"
                        decoration="underline"
                        custom-class="cursor-pointer"
                    />
                </div>
            </div>
        </div>

        <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <EmptyState
                v-if="items && items.length === 0"
                class="bg-white"
                isFull
                description="No records available"
            />
            <!-- <Table v-else :list="getFiltered(items)" @onClickItem="(item, id) => onClickSurvey(item, id)" /> -->
            <FilterTable
                v-else
                :headers="headers"
                isRow
                :list="getFiltered(items)"
                @onClickItem="(item, id) => onClickSurvey(item, id)"
            />
            <CardFooterWithPageButtons
                :current-page="currentPage"
                :per-page="perPage"
                :onChangePage="onChangePage"
                :onChangePerPage="onChangePerPage"
                :total-records="items && items.length"
                class="px-0 bg-opacity-0"
            />
        </div>
        <SurveyResponseDetail
            @onClose="() => (isShowDetail = false)"
            :isShow="isShowDetail"
            @goDetail="() => onGoDetail()"
            :title="get(selectedUserSurvey, 'opportunity.name')"
            :opportunity="get(selectedUserSurvey, 'opportunity')"
            :userSurvey="selectedUserSurvey"
        />
    </div>
</template>

<script>
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Text from "../../atoms/Text/Text";
import Table from "../../organisms/Tables/Simple/Simple.vue";
import FilterTable from "../../organisms/Tables/WithSortAndFilter/WithSortAndFilter.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import EmptyState from "../../organisms/EmptyState/Simple/Simple.vue";
import SurveyResponseDetail from "../../organisms/Modals/SurveyResponseDetail/SurveyResponseDetail.vue";
import {get} from 'lodash'

export default {
    components: {
        Header,
        Text,
        Table,
        CardFooterWithPageButtons,
        EmptyState,
        SurveyResponseDetail,
        FilterTable,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        userSurveys: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isShowDetail: false,
            currentPage: 1,
            perPage: 10,
            headers: [
                { label: "Contact", isSort: true, isAscending: true },
                { label: "Magnitude", isSort: true, isAscending: true },
                { label: "Target Customer", isFilter: true },
                { label: "Opportunity", isFilter: true },
                { label: "Date", isSort: true, isAscending: true },
            ],
			get,
        };
    },
    methods: {
        onGoDetail() {
            this.isShowDetail = false;
            this.$router.push({
                name: "Venture Opportunity Detail",
                params: {
                    ventureId: this.$route.params.ventureId,
                    id: this.selectedUserSurvey?.opportunity?.id,
                },
            });
        },
        getFiltered(items) {
            return items?.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        onClickSurvey(item, index) {
            this.selectedUserSurvey = this.userSurveys?.find(
                (u) => u?.id === item?.id
            );
            this.isShowDetail = true;
        },
        onChangePage(page) {
            this.currentPage = page;
        },
        onChangePerPage(count) {
            this.perPage = count;
        },
    },
};
</script>

<style scoped></style>
