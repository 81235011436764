<template>
    <div class="p-4">
        <div class="flex items-start sm:items-start justify-between w-full" >
            <div class="flex gap-2 items-start sm:items-start mr-2 mt-1.5 sm:mt-0">
                <Text color="black" weight="bold" size="2xl" customClass="md:text-4xl" :content="title" />
                <Badge :label="count.toString()" px="3" textColor="gray-600" bgColor="gray-300" py="1" isRounded textSize="lg" textWeight="bold"  customClass="" />
            </div>

        </div>
        <div class="items-center w-full mt-4 text-gray-500 md:flex md:justify-between" >
            <ButtonGroup :items="links" variant="secondary"></ButtonGroup>
        </div>
         <div class="items-center w-full mt-4 text-gray-500 flex justify-between space-x-2" >
            <InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="searchbox.placeholder" :icon="searchbox.icon" class="min-w-min"/>
            <ButtonWithIcon label="Add Contact" iconName="PlusIcon" variant="primary" size="md" class="h-10 sm:h-10" />
         </div>
    </div>
</template>

<script>
    import Text from "../../../atoms/Text/Text.vue";
    import ButtonWithIcon from "../../Button/WithIcon/WithIcon.vue";
    import InputWithLeadingIcon from "../../Inputs/InputWithLeadingIcon/InputWithLeadingIcon.vue";
    import Link from "../../../atoms/Link/Link.vue"
    import Badge from '../../../molecules/Badge/Basic/Basic.vue'
    import ButtonGroup from '../../../organisms/ButtonGroup/Basic/Basic.vue'

    export default {
        components: {
            Text,
            ButtonWithIcon,
            InputWithLeadingIcon,
            Link,
            Badge,
            ButtonGroup
        },
        name: "TableViewHeader",
        props : {
            title: {
                type: String,
                default: ''
            },
            count: {
                type: Number,
                default: 0
            },
            searchbox: {
                type: Object,
                default: () => {}
            },
            links: {
                type: Object,
                default: () => {}
            },
            isShowSearchBox: {
                type: Boolean,
                default: true
            }
        }

    }
</script>
