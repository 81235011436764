<template>
    <Template :items="items" :userSurveys="userSurveys" />
</template>

<script>
import Template from "../components/templates/VentureSurveyData/VentureSurveyData.vue";
import moment from "moment";

export default {
    components: {
        Template,
    },
    data() {
        return {
            items: [],
            userSurveys: [],
        };
    },
    async mounted() {
        const ventures = await this.actions.venture.getVentures({
            where: { id: this.$route.params?.ventureId },
            getValues: true,
            query: 'ventureSurveyData'
        });
        this.venture = ventures?.[0];
        const items = [];
        const userSurveys = [];
        for (let i = 0; i < this.venture.targetCustomerUsers?.filter(t => !t?.isArchived)?.length; i++) {
            const targetCustomer = this.venture.targetCustomerUsers?.filter(t => !t?.isArchived)[i];
            const data = await this.actions.userSurvey.getUserSurveys({
                where: {
                    survey: { targetCustomerUser: { id: targetCustomer?.id } },
                    isArchived_not: true
                },
                getValues: true,
            });
            console.log(data, "items");
            data.map((item) => {
                userSurveys.push({
                    ...item,
                    opportunity: {
                        ...item.opportunity,
                        magnitudeScore: item?.magnitude,
                        annualizedMagnitude: item?.annualizedMagnitude,
                        desireScore: item?.desire,
                        experience: item?.experience,
                        frequency: item?.frequency,
                        isActionDone: item?.isActionDone,
                    },
                });
                items.push({
                    Contact: `${item?.user?.firstName || ""} ${item?.user?.lastName || ""}`,
                    Magnitude: item?.magnitude,
                    "Target Customer": targetCustomer?.name,
                    Opportunity: item?.opportunity?.name,
                    Date: moment(item?.createdAt).format("MM/DD/YY"),
                    id: item?.id
                });
            });
        }
        this.userSurveys = userSurveys;
        this.items = items?.sort((a, b) => a?.Magnitude > b?.Magnitude ? -1 : 1);
    },
};
</script>

<style scoped></style>
