<template>
  <Menu as="div" class="relative inline-block text-left" :class="customClass">
    <div>
      <MenuButton
        class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        <Icon :name="icon" size="5" color="gray-50" hover-text-color="gray-600" custom-class="group-hover:text-gray-400" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute z-50 right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <template v-for="(item, index) in items" :key="index">
            <MenuItem v-slot="{ active }" as="div">
            <a :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', item.name === 'Clear Filter' ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm overflow-hidden truncate']"
              @click="itemClicked(item)">{{ item.name }}</a>
            </MenuItem>
          </template>
          <!-- <div>
            <MenuItem v-slot="{ active }">
            <button type="submit"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
              @click="actionButtonClicked()">
              {{ buttonText }}
            </button>
            </MenuItem>
          </div> -->
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";

export default {
  name: "DropdownWithMinIcon",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Icon,
  },
  props: {
    /**
    apply custom class on dropdown menu.
    */
    customClass: {
      type: String,
      default: "",
    },
    /**
    footer button text
    */
    buttonText: {
      type: String,
      default: "",
    },
    /**
    list to populate dropdown
    */
    items: {
      type: Array,
      default: () => [],
    },
    /**
      to perform action on item click
     */
    menuItemClicked: {
      type: Function,
      default: () => { },
    },
    /**
      to perform action on footer button
     */
    actionButtonClicked: {
      type: Function,
      default: () => { },
    },
    icon: {
      type: String,
      default: 'DotsVerticalIcon'
    }
  },
  methods: {
    itemClicked: function (item) {
      this.menuItemClicked(item);
    },
    onClick: function (item) {
      this.actionButtonClicked(item);
    },
  },
};
</script>
