<template>
	<TransitionRoot as="template" :show="isShow">
		<Dialog as="div" class="fixed inset-0 overflow-hidden" @close="() => { isLoad = false; $emit('onClose') }">
			<div @keyup.enter.stop="$emit('onClickEnter')" class="absolute inset-0 overflow-hidden">
				<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
					leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</TransitionChild>

				<div class="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
					<!-- <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
						enter-from="translate-x-full" enter-to="translate-x-0"
						leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
						leave-to="translate-x-full"> -->
					<div :class="isLoad ? 'translate-x-0' : 'translate-x-full'"
						class="transform ease-in-out duration-500 sm:duration-700">
						<div
							class="flex flex-col w-screen h-full sm:h-screen overflow-scroll sm:overflow-hidden bg-white shadow-xl pointer-events-auto"
							:class="isLarge ? 'md:max-w-xl' : 'md:max-w-md'">
							<div class="flex-none px-6 py-6 bg-primary-700">
								<div class="flex items-start justify-between">
									<div class="font-medium text-white">
										<Text v-if="subtitle" size="sm" weight="normal" :content="subtitle" />
										<Text :content="title" size="2xl" weight="semibold" color="white" />
									</div>
									<div class="flex items-center ml-3 h-7">
										<button type="button" class="text-gray-400 rounded-md hover:text-gray-500 focus:outline-none "
											@click="() => { isLoad = false; $emit('onClose') }">
											<Icon name="XIcon" size="6" color="gray-100" class="hover:text-gray-300" />
										</button>
									</div>
								</div>
								<div class="mt-2">
									<Text :content="label" color="indigo-100" weight="normal" />
								</div>
							</div>
							<div class="flex-1 h-full overflow-y-hidden sm:overflow-y-auto">
								<slot></slot>
							</div>
							<div v-if="buttons.length === 0 && !hideBottom"
								class="flex justify-end flex-none w-full gap-8 px-8 py-4 bg-gray-100 border-t">
								<Button @click="() => { isLoad = false; $emit('onClose') }" content="Cancel" :variant="null"
									class="text-gray-700 bg-white border" />
								<Button :content="submitBtnText || 'Save'" variant="primary" @click="() => onSubmit()"
									:isLoading="isLoading" />
							</div>
							<div v-else-if="buttons.length > 0 && !hideBottom"
								class="flex justify-end flex-none w-full gap-8 px-8 py-4 bg-gray-100 border-t">
								<Button v-for="button in buttons" :key="button.label" @click="() => onClick(button)"
									:content="button.label" :variant="button.type" :is-loading="button.isLoading"
									:disabled="button.isDisabled" />
							</div>
						</div>
					</div>

					<!-- </TransitionChild> -->
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
/**
 * - Use it to show an empty panel with background overlay
 */
export default {
	emits: ['onClose'],
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		Text,
		Icon,
		Button
	},
	props: {
		/**
		 * use it to show label
		 */
		title: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			default: true,
		},
		buttons: {
			type: Array,
			default: () => []
		},
		isShow: {
			type: Boolean,
			default: false,
		},
		isLarge: {
			type: Boolean,
			default: false,
		},
		submitBtnText: {
			type: String,
			default: ''
		},
		hideBottom: {
			type: Boolean,
			default: false,
		},
		isMultiple: {
			type: Boolean,
			default: false
		},
		subtitle: {
			type: String
		}
		/**
		 * use it for showing side over
		 */
	},
	watch: {
		isShow: function (val) {
			this.open = val;
		}
	},
	methods: {
		onSubmit() {
			this.$emit('onSubmit')
		},
		async onClick(button) {
			await button.action();
			if (!this.isMultiple) {
				this.isLoad = false;
			}
		}
	},
	data() {
		const open = ref(this.isShow)
		return {
			open,
			isLoad: false,
		}
	},
	mounted() {
		setTimeout(() => {
			this.isLoad = true
		}, 10)
	}
}
</script>

