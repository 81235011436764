<template>
    <SideOver
        v-if="isLoad"
        :title="title"
        :subtitle="`${get(userSurvey, 'user.firstName') || ''} ${
            get(userSurvey, 'user.lastName') || ''
        } - ${moment(get(userSurvey, 'createdAt')).format('M/D/YY')}`"
        :is-show="isShow"
        @onClose="onClose"
        hideBottom
        :isLoading="isLoading"
    >
        <div class="flex-1 h-full p-6 space-y-4 overflow-y-auto">
            <a
                @click="() => $emit('goDetail')"
                class="flex items-center space-x-2"
            >
                <Icon
                    name="ArrowLeftIcon"
                    size="3"
                    is-outline
                    color="indigo-700"
                />
                <Text
                    size="sm"
                    weight=""
                    color="indigo-700"
                    content="View Summarized Results"
                />
            </a>
            <div
                class="grid gap-4"
                :class="
                    get(opportunity, 'frequency')
                        ? 'grid-cols-2'
                        : 'grid-cols-1'
                "
            >
                <div
                    class="flex flex-col justify-center items-center py-5 bg-gray-100 space-y-2"
                >
                    <Text
                        size="xs"
                        color="black"
                        v-html="`Opportunity<br>Magnitude`"
                        align="center"
                    />
                    <Text
                        size="2xl"
                        color="black"
                        :content="get(opportunity, 'magnitudeScore') || 0"
                        weight="bold"
                    />
                </div>
                <div
                    v-if="get(opportunity, 'frequency')"
                    class="flex flex-col justify-center items-center py-5 bg-gray-100 space-y-2"
                >
                    <Text
                        size="xs"
                        color="black"
                        v-html="`Annualized<br>Magnitude`"
                        align="center"
                    />
                    <Text
                        size="2xl"
                        color="black"
                        :content="get(opportunity, 'annualizedMagnitude') || 0"
                        weight="bold"
                    />
                </div>
            </div>
            <table class="border w-full divide-y">
                <tr class="divide-x">
                    <td class="text-sm p-2">Opportunity Done</td>
                    <td class="text-sm p-2 font-semibold">
                        {{ get(opportunity, "isActionDone") ? "Yes" : "No" }}
                    </td>
                </tr>
                <tr v-if="get(opportunity, 'frequency')" class="divide-x">
                    <td class="text-sm p-2">Frequency</td>
                    <td class="text-sm p-2 font-semibold">
                        {{
                            `${get(opportunity, "frequency") || ""}/${
                                get(opportunity, "frequencyUnit") || ""
                            }`
                        }}
                    </td>
                </tr>
            </table>
            <div class="py-16">
                <div class="w-full border h-6 grid grid-cols-2">
                    <div class="h-full w-full relative">
                        <div
                            v-if="get(opportunity, 'desireScore', 0) < 0"
                            class="absolute right-0 border-2 h-full"
                            :style="{
                                width: `${Math.abs(
                                    get(opportunity, 'desireScore') * 20
                                )}%`,
                            }"
                            style="
                                border-color: #c547e8;
                                background-color: #c547e84b;
                            "
                        >
                            <div
                                :class="`absolute -left-5 z-20 max-w-xs px-3 py-2 bg-gray-600 rounded-lg shadow-sm`"
                                style="margin-top: -68px"
                            >
                                <Text
                                    content="Desire"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <Text
                                    :content="
                                        get(opportunity, 'desireScore', 0)
                                    "
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <div
                                    :class="`absolute w-4 h-4 -mt-1 transform left-3 rotate-45 bg-gray-600`"
                                    style="z-index: -1"
                                />
                            </div>
                        </div>
                        <div
                            v-if="get(opportunity, 'experience', 0) < 0"
                            class="absolute right-0 border-2 h-full"
                            :style="{
                                width: `${Math.abs(
                                    get(opportunity, 'experience') * 20
                                )}%`,
                            }"
                            style="
                                border-color: #c547e8;
                                background-color: #c547e84b;
                            "
                        >
                            <div
                                :class="`absolute -left-5 z-20 max-w-xs px-3 py-2 mt-8 bg-gray-600 rounded-lg shadow-sm`"
                            >
                                <div
                                    :class="`absolute w-4 h-4 -mt-3 transform rotate-45 bg-gray-600`"
                                    style="z-index: -1"
                                />
                                <Text
                                    content="Experience"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <Text
                                    :content="get(opportunity, 'experience', 0)"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="h-full w-full relative">
                        <div
                            v-if="get(opportunity, 'desireScore', 0) >= 0"
                            class="absolute left-0 border-2 h-full"
                            :style="{
                                width: `${
                                    get(opportunity, 'desireScore') * 20
                                }%`,
                            }"
                            style="
                                border-color: #574ccb;
                                background-color: #574ccb4b;
                            "
                        >
                            <div
                                :class="`absolute -right-5 z-20 max-w-xs px-3 py-2 bg-gray-600 rounded-lg shadow-sm`"
                                style="margin-top: -68px"
                            >
                                <Text
                                    content="Desire"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <Text
                                    :content="
                                        get(opportunity, 'desireScore', 0)
                                    "
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <div
                                    :class="`absolute w-4 h-4 -mt-1 transform right-3 rotate-45 bg-gray-600`"
                                    style="z-index: -1"
                                />
                            </div>
                        </div>
                        <div
                            v-if="get(opportunity, 'experience', 0) >= 0"
                            class="absolute left-0 border-2 h-full"
                            :style="{
                                width: `${
                                    get(opportunity, 'experience') * 20
                                }%`,
                            }"
                            style="
                                border-color: #574ccb;
                                background-color: #574ccb4b;
                            "
                        >
                            <div
                                :class="`absolute -right-5 z-20 max-w-xs px-3 py-2 mt-8 bg-gray-600 rounded-lg shadow-sm`"
                            >
                                <div
                                    :class="`absolute w-4 h-4 -mt-3 transform rotate-45 right-3 bg-gray-600`"
                                    style="z-index: -1"
                                />
                                <Text
                                    content="Experience"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                                <Text
                                    :content="get(opportunity, 'experience', 0)"
                                    size="sm"
                                    weight="medium"
                                    color="white"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="get(whys, 'length') > 0" class="mt-6 space-y-3">
                <Text size="" weight="semibold" content="Why's" color="black" />
                <table class="border w-full divide-y">
                    <tr class="divide-x" v-for="item in whys" :key="item">
                        <td class="text-sm p-2">{{ item }}</td>
                    </tr>
                </table>
            </div>
            <div v-if="get(hows, 'length') > 0" class="mt-6 space-y-3">
                <Text size="" weight="semibold" content="How's" color="black" />
                <table class="border w-full divide-y">
                    <tr class="divide-x" v-for="item in hows" :key="item">
                        <td class="text-sm p-2">{{ item }}</td>
                    </tr>
                </table>
            </div>
            <div v-if="get(problems, 'length') > 0" class="mt-6 space-y-3">
                <Text
                    size=""
                    weight="semibold"
                    content="Problems"
                    color="black"
                />
                <table class="border w-full divide-y">
                    <tr class="divide-x" v-for="item in problems" :key="item">
                        <td class="text-sm p-2">{{ item }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </SideOver>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Select from "../../../molecules/Selects/Component/Component.vue";
import Button from "../../../atoms/Button/Button.vue";
import SideOver from "../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue";
import { get } from "lodash";
import moment from "moment";

export default {
    components: {
        Text,
        Icon,
        Input,
        Select,
        Button,
        SideOver,
    },
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        button: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        opportunity: {
            type: Object,
            default: () => {},
        },
        userSurvey: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        isShow: function (val) {
            if (val) {
                setTimeout(() => (this.isLoad = true), 100);
                this.whys = [];

                this.userSurvey?.answers
                    ?.find((a) => a.question?.category?.includes("Motivator"))
                    ?.answers?.map((a) =>
                        this.whys?.push(
                            a?.metadata?.opportunityName
                                ? a?.metadata?.opportunityName
                                : a?.answer
                        )
                    );
                this.hows = [];
                this.userSurvey?.answers
                    ?.find((a) => a.question?.category?.includes("Solution"))
                    ?.answers?.map((a) =>
                        this.hows?.push(
                            a?.metadata?.opportunityName
                                ? a?.metadata?.opportunityName
                                : a?.answer
                        )
                    );
                this.problems = [];
                this.userSurvey?.answers
                    ?.find((a) => a.question?.category?.includes("Problem"))
                    ?.answers?.map((a) => this.problems?.push(a?.answer));
            } else {
                setTimeout(() => (this.isLoad = false), 700);
            }
        },
    },
    data() {
        return {
            show: false,
            whys: [],
            hows: [],
            problems: [],
            get,
            isLoad: false,
            moment,
        };
    },
    methods: {
        onClose() {
            this.$emit("onClose");
            setTimeout(() => {
                this.isLoad = false;
            }, 700);
        },
    },
};
</script>
